import { useEffect, useState } from 'react';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { Container, Button } from 'react-bootstrap';
import { PageLayout } from './components/PageLayout';
import { IdTokenData } from './components/DataDisplay';
import { loginRequest } from './authConfig';
import Retool from 'react-retool';

import './styles/App.css';


const RetoolWrapper = ({ retoolAppName, userJwt, onError }) => {
    console.log(JSON.stringify(userJwt))
    const [retoolEmbedUrl, setRetoolEmbedUrl] = useState("");
    useEffect(() => {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userJwt}`,
            },
            body: JSON.stringify(
                {
                }
            ),
        };
        fetch("https://eshare-audits-event-handler.azurewebsites.net/api/retool_embed", options)
        .then((res) => {
            // Log the status code
            console.log(`Response status code: ${res.status}`);
    
            // Check if the response was successful
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then((data) => {
            setRetoolEmbedUrl(data.embedUrl);
        })
        .catch((error) => {
            // Handle errors, e.g., network errors, non-2xx status codes, etc.
            console.error('Fetch error:', error);
            onError(error);
        });
    }, [retoolAppName, userJwt, onError]);

    return (
        retoolEmbedUrl && (
            <Container fluid>
                <Retool url={retoolEmbedUrl} />
            </Container>
        )
    );
};


const MainContent = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const handleRedirect = () => {
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create',
            })
            .catch((error) => console.log(error));
    };

    const handleRetoolError = (error) => {
        console.error('RetoolWrapper encountered an error:', error);
        instance.loginRedirect({
            ...loginRequest,
            prompt: 'login',
        });
    };

    return (
        <div className="App">
            {activeAccount ? (
                <RetoolWrapper
                    retoolAppName="Audit scan" // Replace with your actual app name or derive from the token
                    userJwt={activeAccount.idToken}
                    onError={handleRetoolError}
                />
            ) : null}  
        </div>
    );
};


const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <PageLayout>
                <MainContent />
            </PageLayout>
        </MsalProvider>
    );
};

export default App;
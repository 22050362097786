import { AuthenticatedTemplate } from '@azure/msal-react';

import { NavigationBar } from './NavigationBar.jsx';

export const PageLayout = (props) => {
    return (
        <>
            <NavigationBar />
            <br />
            {props.children}
            <br />
            <AuthenticatedTemplate/>
        </>
    );
}